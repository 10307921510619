import React from 'react'
import './GirlledGallary.css'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import sliderData from '../data'





function GirlledGallary() {
    return (
    <div className='gallary-main'>
        <div className='head-title'>
        <i class="fa-regular fa-hand-point-right"></i>
        <h1>Girlled Gallary</h1>
        <i class="fa-regular fa-hand-point-left"></i>
        </div>
        <div className='gallary-photo'>
            <ResponsiveMasonry
            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                <Masonry gutter='20px'>
            {sliderData.map((slide, index)=>{
        return(
                    <div className="gallary " item={slide} key={index}>
                    <img src={slide.img} alt=''/>
                    </div>
        )})}
                </Masonry>
            </ResponsiveMasonry>
        </div>
    </div>
)
}

export default GirlledGallary
