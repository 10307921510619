import React from 'react'
import './GirlledDescription.css'
function GirlledDescription() {
    return (
    <div className='description-main'>
        <div className='menu-description'></div> 
        <div className='menu-description'>
            <h1>Girlled meat</h1>
            <p>Grilled meat is considered one of the most delicious and delicious ways to cook meat, 
            as grilling preserves the benefits of the meat and makes it taste delicious.</p>
        </div>
        
        <div className='menu-description'></div>
        <div className='menu-description'>
            <h1>Girlled chicken</h1>
            <p>Grilled chicken is considered one of the most delicious and delicious ways to cook meat,
            as grilling preserves the benefits of the meat and makes it taste delicious.</p>
        </div>
        <div className='menu-description'>
            <h1>Grilled shrimp</h1>
            <p>Grilling is considered one of the most delicious and delicious cooking methods
                for meat, chicken, fish, seafood, and vegetables, 
            as grilling preserves the benefits of the food and makes it taste delicious.</p>
        </div>
        <div className='menu-description'></div>
        <div className='menu-description'>
            <h1>Grilled sea food</h1>
            <p>Grilling is considered one of the most delicious and delicious cooking methods
                for meat, chicken, fish, seafood, and vegetables, 
            as grilling preserves the benefits of the food and makes it taste delicious.</p>
        </div>
        <div className='menu-description'></div>
        <div className='menu-description'>
            <h1>mix Grill</h1>
            <p>Grilling is considered one of the most delicious and delicious cooking methods
                for meat, chicken, fish, seafood, and vegetables, 
            as grilling preserves the benefits of the food and makes it taste delicious.</p>
        </div>
    </div>
    )
}

export default GirlledDescription
