import React from 'react';
import Data from '../Data/Data'
import MenuMap from './MenuMap';
import './Menu.css'
import Fade from 'react-reveal';




function FastFoodMenu(props) {
    return (
    <div className='menu-main'>
        <div class="title">
                <Fade top duration={2000} delay={2000}>
                <h1>COFFEE MENU</h1>
                </Fade>
        </div>
        <Fade up duration={2000} delay={1000}>
            <div className='menu-item'>
                {Data.Products.map((item, index)=>{
                            return(
                                <MenuMap 
                                img={item.img} 
                                title={item.title}
                                price={item.price}  
                                item={item}
                                key={index}  
                                />
                            )
                        })}
        </div>
        </Fade>
        </div>
    )
}

export default FastFoodMenu
