import React from 'react'
import './Paragraph.css'
import burger from './images/burger (2).jpg'
function Paragraph() {
    return (
    <div className='paragraph-main'>
        <div className='paragraph-letter'>
            <h1>why you chose fast food from foodia ?</h1>
            <p>Fast food refers to food that can be prepared and served quickly. It can come
                from many places: sit-down restaurants, counter service, take-out, drive-thru, and delivery. 
                Fast food is popular because the food is inexpensive, convenient, and tastes good. Fast food may include 
                refined grains instead of whole grains, contain saturated fat, cholesterol, and added sugar, and it can be high in sodium (aka salt)
                which is used as a preservative and makes food more flavorful and satisfying.
            </p>
        </div>
        <div className='paragraph-image'>
            <img src={burger} alt=''/>
        </div>
    </div>
    )
}

export default Paragraph
