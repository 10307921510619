import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Footer from '../components/Commen Components/Footer/Footer'
import Login from '../components/Blog Pages/Login/Login'
import Landing from '../components/Blog Pages/Login/Landing/Landing'
function sign() {
    return (
    <div>
        <Navbar/>
        <Landing/>
        <Login/>
        <Footer/>
    </div>
    )
}

export default sign
