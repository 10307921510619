import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Footer from '../components/Commen Components/Footer/Footer'
import Landing from '../components/Shop Pages/coffee/Landing/Landing'
import Description from '../components/Shop Pages/coffee/Description/Description'
import Menu from '../components/Shop Pages/coffee/Menu/Menu'
import Gallary from '../components/Shop Pages/coffee/Gallary/Gallary'
import Reviews from '../components/Shop Pages/coffee/Reviews/Reviews'
import Booking from '../components/Shop Pages/coffee/Booking/Booking'
import FoodSlider from '../components/Shop Pages/coffee/Slider/FoodSlider'

function Coffee() {
  return (
    <div>
      <Navbar/>
      <Landing/>
      <Menu/>
      <Description/>
      <Reviews/>
      <Gallary/>
      <Booking/>
      <FoodSlider/>
      <Footer/>
    </div>
  )
}

export default Coffee
