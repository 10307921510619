import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import GirlledLanding from '../components/Home Pages/GirlledHome/Landing/GirlledLanding'
import GirlledMenu from '../components/Home Pages/GirlledHome/GirlledMenu/GirlledMenu'
import GirlledGallary from '../components/Home Pages/GirlledHome/Gallary/GirlledGallary'
import GirlledDescription from '../components/Home Pages/GirlledHome/GallaryDescription/GirlledDescription'
import Reviews from '../components/Home Pages/GirlledHome/Reviews/Reviews'
import Services from '../components/Home Pages/GirlledHome/Services/Services'
import FoodSlider from '../components/Home Pages/GirlledHome/FoodSlider/FoodSlider'
import Footer from '../components/Commen Components/Footer/Footer'

function HomeGirlled() {
    return (
        <div>
            <Navbar/>
            <GirlledLanding/>
            <GirlledMenu/>
            <GirlledGallary/>
            <GirlledDescription/>
            <Reviews/>
            <Services/>
            <FoodSlider/>
            <Footer/>
        </div>
    )
}

export default HomeGirlled
