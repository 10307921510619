import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Landing from '../components/Home Pages/BigMealHome/Landing/Landing'
import Menu from '../components/Home Pages/BigMealHome/Menu/Menu'
import Booking from '../components/Home Pages/BigMealHome/Booking/Booking'
import Footer from '../components/Commen Components/Footer/Footer'
import MLanding from '../components/Home Pages/BigMealHome/MLanding/MLanding'
import Description from '../components/Home Pages/BigMealHome/Description/Description'
import Services from '../components/Home Pages/BigMealHome/Services/Services'
function HomeBigMeals() {
  return (
    <div>
        <Navbar/>
        <Landing/>
        <Menu/>
        <MLanding/>
        <Services/>
        <Booking/>
        <Description/>
        <Footer/>
    </div>
  )
}

export default HomeBigMeals
