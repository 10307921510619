import React from 'react'
import Section from '../components/Blog Pages/Menus/Menus'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Landing from '../components/Home Pages/RestaurantHome/Landing/Landing'
import Footer from '../components/Commen Components/Footer/Footer'


function Sections() {
return (
    <div>
        <Navbar/>
        <Landing/>
        <Section/>
        <Footer/>
    </div>
)
}

export default Sections
