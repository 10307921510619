import React from 'react'
import './Menus.css'
import { Button } from "react-bootstrap";
import  { useCart }  from "react-use-cart";
import Fade from 'react-reveal';

const SectionMap = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {addItem} = useCart();
return (
    <Fade top duration={1500} delay={1000}>
    <div className="section-map-meal ">
        <img src={props.img } alt=''/>
        <h2>{props.title}</h2>
        <p>{props.price}$</p>
        <h3>contants</h3>
        <p>{props.contant}</p>
        <Button variant="success" onClick={()=>addItem(props.item)}>
        Add to Cart</Button>
    </div>
    </Fade>
)
}

export default SectionMap;
