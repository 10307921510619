import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Footer from '../components/Commen Components/Footer/Footer'
import Register from '../components/Blog Pages/Register/Register'
import Landing from '../components/Blog Pages/Register/Landing/Landing'
function signup() {
    return (
    <div>
        <Navbar/>
        <Landing/>
        <Register/>
        <Footer/>
    </div>
    )
}

export default signup
