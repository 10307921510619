import React from 'react'
import './BlogPage.css'
import img1 from './images/img1.jpg'
import img2 from './images/img2.jpg'
import img3 from './images/img3.jpg'
import img4 from './images/img4.jpg'
import img5 from './images/img5.jpg'
import img6 from './images/img6.jpg'
import img7 from './images/big meal (4).jpg'
import img8 from './images/coffee (8).jpg'
import img9 from './images/ice cream (7).jpg'
import img10 from './images/grilled (6).jpg'
import img11 from './images/coffee (2).jpg'
import img12 from './images/big meal (1).jpg'


function BlogPage() {
    return (
    <div className='blog-main'>
        <div className='blog-title'>
            <h1>foodia blog</h1>
        </div>
        <div className='blog-body'>
            <div className='reviewCustomer'>
                    <div className='review'>
                    <img src={img3} alt=''/>
                    <h1> maria</h1>
                    </div>
                    <div className='review-artical'>
                    <i class="fa-solid fa-quote-left"></i>
                    <p>I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        the service was excellent and always with a smile always with a smile</p>
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
            </div>
            <div className='gallary'>
                <img src={img7} alt=''/>
            </div>
        </div>
        <div className='blog-body'>
            <div className='reviewCustomer'>
                    <div className='review'>
                    <img src={img1} alt=''/>
                    <h1>jaseka</h1>
                    </div>
                    <div className='review-artical'>
                    <i class="fa-solid fa-quote-left"></i>
                    <p>I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        the service was excellent and always with a smile always with a smile</p>
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
            </div>
            <div className='gallary'>
                <img src={img8} alt=''/>
            </div>
        </div>
        <div className='blog-body'>
            <div className='reviewCustomer'>
                    <div className='review'>
                    <img src={img2} alt=''/>
                    <h1>jasy</h1>
                    </div>
                    <div className='review-artical'>
                    <i class="fa-solid fa-quote-left"></i>
                    <p>I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        the service was excellent and always with a smile always with a smile</p>
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
            </div>
            <div className='gallary'>
                <img src={img9} alt=''/>
            </div>
        </div>
        <div className='blog-body'>
            <div className='reviewCustomer'>
                    <div className='review'>
                    <img src={img4} alt=''/>
                    <h1> mark</h1>
                    </div>
                    <div className='review-artical'>
                    <i class="fa-solid fa-quote-left"></i>
                    <p>I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        the service was excellent and always with a smile always with a smile</p>
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
            </div>
            <div className='gallary'>
                <img src={img10} alt=''/>
            </div>
        </div>
        <div className='blog-body'>
            <div className='reviewCustomer'>
                    <div className='review'>
                    <img src={img5} alt=''/>
                    <h1> angela</h1>
                    </div>
                    <div className='review-artical'>
                    <i class="fa-solid fa-quote-left"></i>
                    <p>I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        the service was excellent and always with a smile always with a smile</p>
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
            </div>
            <div className='gallary'>
                <img src={img11} alt=''/>
            </div>
        </div>
        <div className='blog-body'>
            <div className='reviewCustomer'>
                    <div className='review'>
                    <img src={img6} alt=''/>
                    <h1> jac</h1>
                    </div>
                    <div className='review-artical'>
                    <i class="fa-solid fa-quote-left"></i>
                    <p>I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        the service was excellent and always with a smile always with a smile</p>
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
            </div>
            <div className='gallary'>
                <img src={img12} alt=''/>
            </div>
        </div>
    </div>
    )
}

export default BlogPage
