import React from 'react'
import './Gallary.css'
import Data from '../Data';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

function Gallary() {
  return (
    <div className='gallary-main'>
        <div className='head-title'>
        <i class="fa-regular fa-hand-point-right"></i>
        <h1> Gallary</h1>
        <i class="fa-regular fa-hand-point-left"></i>
        </div>
        <div className='gallary-photo'>
            <ResponsiveMasonry
            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                <Masonry gutter='20px'>
            {Data.Products.map((slide, index)=>{
        return(
                    <div className="gallary " item={slide} key={index}>
                        <div className='gallary-image'>
                            <img src={slide.img} alt=''/>
                        </div>
                    </div>
        )})}
                </Masonry>
            </ResponsiveMasonry>
        </div>
    </div>
  )
}

export default Gallary
