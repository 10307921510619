import React from 'react'
import './Gallary.css'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import gallaryData from './Data'






function GirlledGallary() {
    return (
    <div className='gallary-main'>
        <div className='head-title'>
        <i class="fa-regular fa-hand-point-right"></i>
        <h1>Restaurant Gallary</h1>
        <i class="fa-regular fa-hand-point-left"></i>
        </div>
        <div className='gallary-photo'>
            <ResponsiveMasonry
            columnsCountBreakPoints={{350: 1, 750: 3, 900: 4}}>
                <Masonry gutter='20px'>
            {gallaryData.map((slide, index)=>{
        return(
                    <div className="gallary " item={slide} key={index}>
                        <div className='gallary-image'>
                            <img src={slide.img} alt=''/>
                        </div>
                        <div className='gallary-title'>
                            <h1>{slide.title}</h1>
                        </div>
                    </div>
        )})}
                </Masonry>
            </ResponsiveMasonry>
        </div>
    </div>
)
}

export default GirlledGallary
