import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import './Landing.css'
import big1 from './images/big meal (1).jpg'
import big2 from './images/big meal (2).jpg'
import big3 from './images/big meal (3).jpg'
import big4 from './images/big meal (4).jpg'
import big5 from './images/big meal (5).jpg'

function Reviews() {
    return (
    <div className='landing-main'>
            <Swiper
            spaceBetween={30}
            effect={'fade'}
            navigation={true}
            autoplay={{
            delay: 1500,
            disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            loop={true}
            modules={[EffectFade, Navigation, Pagination, Autoplay]}
            className="slider-body"
            >
            <SwiperSlide className='swiper-slide'>
                <img src={big1} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={big2} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={big3} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={big4} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={big5} alt=''/>
            </SwiperSlide>
            </Swiper>
    </div>
    )
}

export default Reviews
