import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar';
import Landing from '../components/About Us Pages/About/Landing/Landing';
import Footer from '../components/Commen Components/Footer/Footer'; 
import About from '../components/About Us Pages/About/About'; 


function AboutPage() {
return (
    <div>
        <Navbar/>
        <Landing/>
        <About/>
        <Footer/>
    </div>
)
}

export default AboutPage
