import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Landing from '../components/Blog Pages/Gallary/Landing/Landing'
import Gallary from '../components/Blog Pages/Gallary/Gallary'
import Footer from '../components/Commen Components/Footer/Footer'

function gallaryPage() {
  return (
    <div>
        <Navbar/>
        <Landing/>
        <Gallary/>
        <Footer/>
    </div>
  )
}

export default gallaryPage
