import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Landing from '../components/Shop Pages/furits and juices/Landing/Landing'
import FoodSlider from '../components/Shop Pages/furits and juices/Slider/FoodSlider'
import Menu from '../components/Shop Pages/furits and juices/Menu/Menu'
import Footer from '../components/Commen Components/Footer/Footer'
import Description from '../components/Shop Pages/furits and juices/Description/Description'
import Reviews from '../components/Shop Pages/furits and juices/Reviews/Reviews'
import Gallary from '../components/Shop Pages/furits and juices/Gallary/Gallary'
import Booking from '../components/Shop Pages/furits and juices/Booking/Booking'

function Fruits() {
  return (
    <div>
      <Navbar/>
      <Landing/>
      <FoodSlider/>
      <Menu/>
      <Description/>
      <Reviews/>
      <Gallary/>
      <Booking/>
      <Footer/>
    </div>
  )
}

export default Fruits
