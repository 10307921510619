import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation'
// import required modules
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import img1 from './images/slider (1).jpg'
import img2 from './images/slider (2).jpg'
import img3 from './images/slider (3).jpg'
import img4 from './images/slider (4).jpg'
import img5 from './images/slider (5).jpg'
import img6 from './images/slider (6).jpg'
import img7 from './images/slider (7).jpg'
import img8 from './images/slider (8).jpg'
import img9 from './images/slider (9).jpg'
import img10 from './images/slider (10).jpg'
import img11 from './images/slider (11).jpg'
import img12 from './images/slider (12).jpg'

import './FoodSlider.css'
function FoofSlider() {
    return (
        <div className='food-slider-main'>
            <Swiper
            slidesPerView={3}
            spaceBetween={30}
            navigation={true}
            autoplay={{
            delay: 3500,
            pauseOnMouseEnter: true
            }}
            pagination={{
                clickable: true,
            }}
            loop={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
        >
            <SwiperSlide><img className='slider-image' src={img1} alt=''/></SwiperSlide>
            <SwiperSlide><img className='slider-image' src={img2} alt=''/></SwiperSlide>
            <SwiperSlide><img className='slider-image' src={img3} alt=''/></SwiperSlide>
            <SwiperSlide><img className='slider-image' src={img4} alt=''/></SwiperSlide>
            <SwiperSlide><img className='slider-image' src={img5} alt=''/></SwiperSlide>
            <SwiperSlide><img className='slider-image' src={img6} alt=''/></SwiperSlide>
            <SwiperSlide><img className='slider-image' src={img7} alt=''/></SwiperSlide>
            <SwiperSlide><img className='slider-image' src={img8} alt=''/></SwiperSlide>
            <SwiperSlide><img className='slider-image' src={img9} alt=''/></SwiperSlide>
            <SwiperSlide><img className='slider-image' src={img10} alt=''/></SwiperSlide>
            <SwiperSlide><img className='slider-image' src={img11} alt=''/></SwiperSlide>
            <SwiperSlide><img className='slider-image' src={img12} alt=''/></SwiperSlide>
        </Swiper>
        </div>
    )
}

export default FoofSlider
