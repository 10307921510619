import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Landing from '../components/Shop Pages/desserts/Landing/Landing'
import Menu from '../components/Shop Pages/desserts/Menu/Menu'
import Booking from '../components/Shop Pages/desserts/Booking/Booking'
import Footer from '../components/Commen Components/Footer/Footer'
import Description from '../components/Shop Pages/desserts/Description/Description'
import FoofSlider from '../components/Shop Pages/desserts/Slider/FoodSlider'
import Gallary from '../components/Shop Pages/desserts/Gallary/Gallary'
import Reviews from '../components/Shop Pages/desserts/Reviews/Reviews'

function Desserts() {
  return (
    <div>
      <Navbar/>
      <Landing/>
      <Menu/>
      <Booking/>
      <Description/>
      <FoofSlider/>
      <Gallary/>
      <Reviews/>
      <Footer/>
    </div>
  )
}

export default Desserts
