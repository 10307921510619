import React from 'react'
import './Menu.css'
import { Button } from 'react-bootstrap'
import { useCart } from 'react-use-cart';
import Fade from 'react-reveal';

function MenuMap(props) {
    const {addItem} = useCart();
    return (
        <div>
        <Fade up duration={2000} delay={1000}>
            <div className="section-map-meal-furits ">
                <img src={props.img } alt=''/>
                <h2>{props.title}</h2>
                <p>{props.price}$</p>
                <Button variant="success" onClick={()=>addItem(props.item)}>
                Add to Cart</Button>
            </div>
        </Fade>
        </div>
    )
}

export default MenuMap
