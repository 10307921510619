import React from 'react'
import './Booking.css'
import { Form, Button } from 'react-bootstrap'

function Booking() {
    return (
        <div className='booking-main'>
            <h1>Book A Table</h1>
            <Form>
                <div className='contact-in'>
                    <div className='contact-in-1'>
                        <input class="input-in" type="text" id="name" placeholder="your name" data-sb-validations="required"></input>
                        <input class="input-in" type="tel" id="phone" placeholder="your phone" data-sb-validations="required"></input>
                        <input class="input-in" type="number" id="number" placeholder="How Meny People" data-sb-validations="required"></input>
                    </div>
                    <div className='contact-in-2'>
                        <textarea class="input-in" id="message" type="text" placeholder="your message"></textarea>
                    </div>
                </div>
                <div className='contact-send'>
                    <Button class="smess">Book Now</Button>
                </div>
            </Form>
        </div>
    )
}

export default Booking