import React from 'react'
import './MainHome.css'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Fade } from 'react-reveal'
import img1 from './images/restuarant.png'
import img2 from './images/fast.png'
import img3 from './images/desserts.png'
import img4 from './images/coffee.png'
import img5 from './images/girlled1.png'
import img6 from './images/girlled.png'
import img7 from './images/fruits.png'
import img8 from './images/ice cream.png'
import img9 from './images/menus.png'
import img10 from './images/gallary.png'
import img11 from './images/about.png'
import img12 from './images/blog.png'
import img13 from './images/my cart.png'
import img14 from './images/payment.png'
import img15 from './images/signin.png'
import img16 from './images/signup.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

function MainHome() {
    const scrollTop =()=>{
        window.scrollTo({
            top:0,
            behavior:'smooth',
        })
    }
    return (
    <div>
        <div className='home-landing'>
            <div className='landing-intro'>
                <div className='intro_body'>
                    <h1>Foodia</h1>
                    <p className='intro-letter'>why you should buy foodia template?!!!!</p>
                    <p className='intro-letter'>don't be too confused Here is the answer my dear friend foodia is a responsive template with pc, tap, laptop and mobile 
                        if you have an idea about serving meals or you are already have aproject don't worry foodia will meet your needs
                    </p>
                    <Button variant="outline-warning">learn more</Button>
                </div>
            </div>
        </div>
        <div className='home-pages'>
            <div className='home-header'>
                <h1>Other Demos</h1>
                <p>Check other default demos, which pretty much show some features available</p>
            </div>
            
                <Fade up duration={500} delay={500}>
                    <Link to="/HomeRestaurant">
                    <div className='page-link'>
                        <img src={img1} alt=''/>
                        <h1>restuarant Home</h1>
                    </div>
                    </Link>
                </Fade>
                <Fade up duration={500} delay={1000}>
                    <Link to="/HomeFastFood">
                    <div className='page-link'>
                        <img src={img2} alt=''/>
                        <h1>fast food Home</h1>
                    </div>
                    </Link>
                </Fade>
                <Fade up duration={500} delay={1500}>
                <Link to="/Desserts">
                    <div className='page-link'>
                        <img src={img3} alt=''/>
                        <h1>desserts Home</h1>
                    </div>
                </Link>
                </Fade>
                <Fade up duration={500} delay={2000}>
                <Link to="/Coffee">
                    <div className='page-link'>
                        <img src={img4} alt=''/>
                        <h1>coffee Home</h1>
                    </div>
                </Link>
                </Fade>
                <Fade up duration={500} delay={500}>
                <Link to="/HomeGirlled">
                    <div className='page-link'>
                        <img src={img5} alt=''/>
                        <h1>girlled Home</h1>
                    </div>
                </Link>
                </Fade>
                <Fade up duration={500} delay={1000}>
            <Link to="/HomeBigMeals">
                <div className='page-link'>
                    <img src={img6} alt=''/>
                    <h1>Big Meals Home</h1>
                </div>
            </Link>
            </Fade>
            <Fade up duration={500} delay={1500}>
            <Link to="/Fruits">
                <div className='page-link'>
                    <img src={img7} alt=''/>
                    <h1>Fruits Home</h1>
                </div>
            </Link>
            </Fade>
            <Fade up duration={500} delay={2000}>
            <Link to="/IceCream">
                <div className='page-link'>
                    <img src={img8} alt=''/>
                    <h1>ice cream Home</h1>
                </div>
            </Link>
            </Fade>
            <Fade up duration={500} delay={500}>
            <Link to="/Menus">
                <div className='page-link'>
                    <img src={img9} alt=''/>
                    <h1>menus Home</h1>
                </div>
            </Link>
            </Fade>
            <Fade up duration={500} delay={1000}>
            <Link to="/Gallary">
                <div className='page-link'>
                    <img src={img10} alt=''/>
                    <h1>gallary Home</h1>
                </div>
            </Link>
            </Fade>
            <Fade up duration={500} delay={1500}>
            <Link to="/About">
                <div className='page-link'>
                    <img src={img11} alt=''/>
                    <h1>about Home</h1>
                </div>
            </Link>
            </Fade>
            <Fade up duration={500} delay={2000}>
            <Link to="/Blog">
                <div className='page-link'>
                    <img src={img12} alt=''/>
                    <h1>blog Home</h1>
                </div>
            </Link>
            </Fade>
            <Fade up duration={500} delay={500}>
            <Link to="/MyCart">
                <div className='page-link'>
                    <img src={img13} alt=''/>
                    <h1>my cart Home</h1>
                </div>
            </Link>
            </Fade>
            <Fade up duration={500} delay={1000}>
            <Link to="/Payment">
                <div className='page-link'>
                    <img src={img14} alt=''/>
                    <h1>Payment Home</h1>
                </div>
            </Link>
            </Fade>
            <Fade up duration={500} delay={1500}>
            <Link to="/Payment">
                <div className='page-link'>
                    <img src={img15} alt=''/>
                    <h1>Login Home</h1>
                </div>
            </Link>
            </Fade>
            <Fade up duration={500} delay={2000}>
            <Link to="/Payment">
                <div className='page-link'>
                    <img src={img16} alt=''/>
                    <h1>Register Home</h1>
                </div>
            </Link>
            </Fade>
        </div>
        <div className='home-break'>
            <div className='home-break-intro'>
            </div>
        </div>
        <div className='home-skills'>
            <div className='home-header'>
                <h1>used tools</h1>
            </div>
            <div className='home-tool'>
                <div className='icons'>
                    <i class="fa-brands fa-html5"></i>
                </div>
                <h1>html5</h1>
            </div>
            <div className='home-tool'>
                <div className='icons'>
                    <i class="fa-brands fa-css3-alt"></i>
                </div>
                <h1>css3</h1>
            </div>
            <div className='home-tool'>
                <div className='icons'>
                    <i class="fa-brands fa-square-js"></i>
                </div>
                <h1>JavaScript</h1>
            </div>
            <div className='home-tool'>
                <div className='icons'>
                    <i class="fa-brands fa-react"></i>
                </div>
                <h1>React.JS</h1>
            </div>
        </div>
        <div className='home-skills'>
            <div className='home-header'>
                <h1>used Libraries</h1>
            </div>
            <div className='home-tool'>
                <div className='icons'>
                    <i class="fa-brands fa-react"></i>
                </div>
                <h1>React-bootstrap</h1>
            </div>
            <div className='home-tool'>
                <div className='icons'>
                    <i class="fa-brands fa-react"></i>
                </div>
                <h1>React-reveal</h1>
            </div>
            <div className='home-tool'>
                <div className='icons'>
                    <i class="fa-brands fa-react"></i>
                </div>
                <h1>react-router-dom</h1>
            </div>
            <div className='home-tool'>
                <div className='icons'>
                    <i class="fa-brands fa-react"></i>
                </div>
                <h1>react-use-cart</h1>
            </div>
            <div className='home-tool'>
                <div className='icons'>
                    <i class="fa-brands fa-react"></i>
                </div>
                <h1>react-responsive-masonry</h1>
            </div>
            <div className='home-tool'>
                <div className='icons'>
                    <i class="fa-brands fa-react"></i>
                </div>
                <h1>react-swiper</h1>
            </div>
            <div className='home-tool'>
                <div className='icons'>
                    <i class="fa-brands fa-react"></i>
                </div>
                <h1>react-icons</h1>
            </div>
        </div>
        <div className='home-slider'>
            <h1> Foodia home images</h1>
            <Swiper
            spaceBetween={30}
            effect={'fade'}
            navigation={true}
            autoplay={{
            delay: 1500,
            disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            loop={true}
            modules={[EffectFade, Navigation, Pagination, Autoplay]}
            className="mySwiper"
            >
            <SwiperSlide className='swiper-slide'>
                <img src={img1} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={img2} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={img3} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={img4} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={img9} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={img11} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={img5} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={img6} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={img16} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={img7} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={img8} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={img15} alt=''/>
            </SwiperSlide>
            </Swiper>
        </div>
        <div className='home-footer'>
            <Button variant="outline-warning" onClick={scrollTop}>buy now</Button>
        </div>
    </div>
    )
}

export default MainHome
