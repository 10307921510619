import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import img1 from '../images3/img1.jpg'
import img2 from '../images3/img2.jpg'
import img3 from '../images3/img3.jpg'
import img4 from '../images3/img4.jpg'
import './Reviews.css'

function Reviews() {
    return (
    <div className='grilled-review-main'>
        <h1>People said about Foodia</h1>
            <Swiper
            spaceBetween={30}
            effect={'fade'}
            navigation={true}
            autoplay={{
            delay: 3500,
            disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            loop={true}
            modules={[EffectFade, Pagination, Autoplay]}
            className="mySwiper"
            >
            <SwiperSlide >
                <div className='grilled-review-customer'>
                    <div className='g-review'>
                    <img src={img1} alt=''/>
                    <h1> anna</h1>
                    </div>
                    <div className='g-review-artical'>
                    <i class="fa-solid fa-quote-left"></i>
                    <p>I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile</p>
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='grilled-review-customer'>
                    <div className='g-review'>
                    <img src={img2} alt=''/>
                    <h1> jnna</h1>
                    </div>
                    <div className='g-review-artical'>
                    <i class="fa-solid fa-quote-left"></i>
                    <p>I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile always with a smile</p>
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='grilled-review-customer'>
                    <div className='g-review'>
                    <img src={img3} alt=''/>
                    <h1> maria</h1>
                    </div>
                    <div className='g-review-artical'>
                    <i class="fa-solid fa-quote-left"></i>
                    <p>I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        the service was excellent and always with a smile always with a smile</p>
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='grilled-review-customer'>
                    <div className='g-review'>
                    <img src={img4} alt=''/>
                    <h1> jan</h1>
                    </div>
                    <div className='g-review-artical'>
                    <i class="fa-solid fa-quote-left"></i>
                    <p>I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smile
                        I enjoyed the delicious grilled food, the place was great, 
                        the service was excellent and always with a smilethe service was excellent 
                        </p>
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
                </div>
            </SwiperSlide>
            </Swiper>
    </div>
    )
}

export default Reviews
