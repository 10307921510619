import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Meals.css';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import pizzabox from './images/pizza2.jpg' 
import pizza from './images/pizza3.jpg' 
import burger from './images/burger.jpg' 
import chickin from './images/chikin.jpg' 
import desert from './images/desert.jpg' 
import shawerma from './images/shawerma1.jpg' 
import shawermachickin from './images/shawerma2.jpg' 
import cake from './images/desert cake.jpg' 
import chickinburger from './images/chickin burger.jpg' 



function Meals() {
return (
    <div className='meals-swiper'>
    <h1>best seller meals</h1>
    <>
    <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
        
        delay: 3500,
        disableOnInteraction: false,
        }}
        pagination={{
            
        clickable: true,
        }}
        loop={true}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
    >
        <SwiperSlide>
            <img src={pizzabox} alt=''/>
            <div className='swiper-body'>
                <h1>pizza box</h1>
                
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={pizza} alt=''/>
            <div className='swiper-body'>
                <h1>pizza </h1>
                
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={burger} alt=''/>
            <div className='swiper-body'>
                <h1>burger</h1>
                
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={chickin} alt=''/>
            <div className='swiper-body'>
                <h1>pizza box</h1>
                
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={desert} alt=''/>
            <div className='swiper-body'>
                <h1>deserts</h1>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={shawerma} alt=''/>
            <div className='swiper-body'>
                <h1>meet shawerma</h1>
            
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={chickinburger} alt=''/>
            <div className='swiper-body'>
                <h1>chickin burger</h1>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={cake} alt=''/>
            <div className='swiper-body'>
                <h1>cub cake</h1>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={shawermachickin} alt=''/>
            <div className='swiper-body'>
                <h1>chickin shawerma</h1>
                
            </div>
        </SwiperSlide>
    </Swiper>
    </>
    </div>
)
}

export default Meals
