import React from 'react'
import './GirlledMenu.css'
import Data from './Data/Data'
import GirrledMenuMap from'./GirreldMenuMap'
function GirlledMenu(props) {
    return (
    <div className='girlled-menu-main'>
        <div className='head-title'>
        <i class="fa-regular fa-hand-point-right"></i>
        <h1>Girlled Menu</h1>
        <i class="fa-regular fa-hand-point-left"></i>
        </div>
        <div className="girlled-menu-items">
        {Data.productData5.map((item, index)=>{
                        return(
                            <GirrledMenuMap 
                            img={item.img} 
                            title={item.title}
                            price={item.price} 
                            contant={item.contant} 
                            item={item}
                            key={index}  
                            />
                        )
                    })}
        </div>
    </div>
    ) 
}
export default GirlledMenu;