import React from 'react'
import { useState} from 'react'
import './Menus.css'
import  MenusMap  from './MenusMap'
import data from './Data'
import { FaPlus, FaMinus} from 'react-icons/fa';


const Sections = (props)=> {
    const [ toggleMenu, setToggleMenu] = useState(false);
    const [ toggleMenu1, setToggleMenu1] = useState(false);
    const [ toggleMenu2, setToggleMenu2] = useState(false);
    const [ toggleMenu3, setToggleMenu3] = useState(false);
    const [ toggleMenu4, setToggleMenu4] = useState(false);
    const [ toggleMenu5, setToggleMenu5] = useState(false);
    const [ toggleMenu6, setToggleMenu6] = useState(false);
    const [ toggleMenu7, setToggleMenu7] = useState(false);
    const [ toggleMenu8, setToggleMenu8] = useState(false);
    const [ toggleMenu9, setToggleMenu9] = useState(false);
return (
    <div className='main-sections'>
        <h1>our Menus</h1>
        <p>chose your favourite meal</p>
        <div className='section-1'>
            <div className='section-header' >
                <h1>pizza</h1>
                {toggleMenu
                ? <FaMinus className='s-icons' icon="fa-solid fa-minus"size={27}  onClick={() => setToggleMenu(false)}/>
                : <FaPlus className='s-icons' icon="fa-solid fa-plus" size={27} onClick={() => setToggleMenu(true)}/>
                }
                
            </div>
            {toggleMenu && (
            <div className='section-body'>
                {data.productData.map((item, index)=>{
                        return(
                            <MenusMap 
                            img={item.img} 
                            title={item.title}
                            price={item.price} 
                            contant={item.contant} 
                            item={item}
                            key={index}  
                            />
                        )
                    })}
            </div>
            )}
        </div>
        <div className='section-1'>
            <div className='section-header' >
                <h1>big meals</h1>
                {toggleMenu1
                ? <FaMinus className='s-icons' icon="fa-solid fa-minus"size={27}  onClick={() => setToggleMenu1(false)}/>
                : <FaPlus className='s-icons' icon="fa-solid fa-plus" size={27} onClick={() => setToggleMenu1(true)}/>
                }
                
            </div>
            {toggleMenu1 && (
            <div className='section-body'>
                {data.productData2.map((item, index)=>{
                        return(
                            <MenusMap 
                            img={item.img} 
                            title={item.title}
                            price={item.price} 
                            contant={item.contant} 
                            item={item}
                            key={index}  
                            />
                        )
                    })}
            </div>
            )}
        </div>
        <div className='section-1'>
            <div className='section-header' >
                <h1>burger</h1>
                {toggleMenu2
                ? <FaMinus className='s-icons' icon="fa-solid fa-minus"size={27}  onClick={() => setToggleMenu2(false)}/>
                : <FaPlus className='s-icons' icon="fa-solid fa-plus" size={27} onClick={() => setToggleMenu2(true)}/>
                }
                
            </div>
            {toggleMenu2 && (
            <div className='section-body'>
                {data.productData3.map((item, index)=>{
                        return(
                            <MenusMap 
                            img={item.img} 
                            title={item.title}
                            price={item.price} 
                            contant={item.contant} 
                            item={item}
                            key={index}  
                            />
                        )
                    })}
            </div>
            )}
        </div>
        <div className='section-1'>
            <div className='section-header' >
                <h1>crispy meals</h1>
                {toggleMenu3
                ? <FaMinus className='s-icons' icon="fa-solid fa-minus"size={27}  onClick={() => setToggleMenu3(false)}/>
                : <FaPlus className='s-icons' icon="fa-solid fa-plus" size={27} onClick={() => setToggleMenu3(true)}/>
                }
                
            </div>
            {toggleMenu3 && (
            <div className='section-body'>
                {data.productData4.map((item, index)=>{
                        return(
                            <MenusMap 
                            img={item.img} 
                            title={item.title}
                            price={item.price} 
                            contant={item.contant} 
                            item={item}
                            key={index}  
                            />
                        )
                    })}
            </div>
            )}
        </div>
        <div className='section-1'>
            <div className='section-header' >
                <h1>grilled</h1>
                {toggleMenu4
                ? <FaMinus className='s-icons' icon="fa-solid fa-minus"size={27}  onClick={() => setToggleMenu4(false)}/>
                : <FaPlus className='s-icons' icon="fa-solid fa-plus" size={27} onClick={() => setToggleMenu4(true)}/>
                }
                
            </div>
            {toggleMenu4 && (
            <div className='section-body'>
                {data.productData5.map((item, index)=>{
                        return(
                            <MenusMap 
                            img={item.img} 
                            title={item.title}
                            price={item.price} 
                            contant={item.contant} 
                            item={item}
                            key={index}  
                            />
                        )
                    })}
            </div>
            )}
        </div>
        <div className='section-1'>
            <div className='section-header' >
                <h1>shawerma</h1>
                {toggleMenu5
                ? <FaMinus className='s-icons' icon="fa-solid fa-minus"size={27}  onClick={() => setToggleMenu5(false)}/>
                : <FaPlus className='s-icons' icon="fa-solid fa-plus" size={27} onClick={() => setToggleMenu5(true)}/>
                }
                
            </div>
            {toggleMenu5 && (
            <div className='section-body'>
                {data.productData6.map((item, index)=>{
                        return(
                            <MenusMap 
                            img={item.img} 
                            title={item.title}
                            price={item.price} 
                            contant={item.contant} 
                            item={item}
                            key={index}  
                            />
                        )
                    })}
            </div>
            )}
        </div>
        <div className='section-1'>
            <div className='section-header' >
                <h1>coffee</h1>
                {toggleMenu6
                ? <FaMinus className='s-icons' icon="fa-solid fa-minus"size={27}  onClick={() => setToggleMenu6(false)}/>
                : <FaPlus className='s-icons' icon="fa-solid fa-plus" size={27} onClick={() => setToggleMenu6(true)}/>
                }
                
            </div>
            {toggleMenu6 && (
            <div className='section-body'>
                {data.productData7.map((item, index)=>{
                        return(
                            <MenusMap 
                            img={item.img} 
                            title={item.title}
                            price={item.price} 
                            contant={item.contant} 
                            item={item}
                            key={index}  
                            />
                        )
                    })}
            </div>
            )}
        </div>
        <div className='section-1'>
            <div className='section-header' >
                <h1>Desserts</h1>
                {toggleMenu7
                ? <FaMinus className='s-icons' icon="fa-solid fa-minus"size={27}  onClick={() => setToggleMenu7(false)}/>
                : <FaPlus className='s-icons' icon="fa-solid fa-plus" size={27} onClick={() => setToggleMenu7(true)}/>
                }
                
            </div>
            {toggleMenu7 && (
            <div className='section-body'>
                {data.ProductData8.map((item, index)=>{
                        return(
                            <MenusMap 
                            img={item.img} 
                            title={item.title}
                            price={item.price} 
                            contant={item.contant} 
                            item={item}
                            key={index}  
                            />
                        )
                    })}
            </div>
            )}
        </div>
        <div className='section-1'>
            <div className='section-header' >
                <h1>fruits and juices</h1>
                {toggleMenu8
                ? <FaMinus className='s-icons' icon="fa-solid fa-minus"size={27}  onClick={() => setToggleMenu8(false)}/>
                : <FaPlus className='s-icons' icon="fa-solid fa-plus" size={27} onClick={() => setToggleMenu8(true)}/>
                }
                
            </div>
            {toggleMenu8 && (
            <div className='section-body'>
                {data.ProductData9.map((item, index)=>{
                        return(
                            <MenusMap 
                            img={item.img} 
                            title={item.title}
                            price={item.price} 
                            contant={item.contant} 
                            item={item}
                            key={index}  
                            />
                        )
                    })}
            </div>
            )}
        </div>
        <div className='section-1'>
            <div className='section-header' >
                <h1>ice cream</h1>
                {toggleMenu9
                ? <FaMinus className='s-icons' icon="fa-solid fa-minus"size={27}  onClick={() => setToggleMenu9(false)}/>
                : <FaPlus className='s-icons' icon="fa-solid fa-plus" size={27} onClick={() => setToggleMenu9(true)}/>
                }
                
            </div>
            {toggleMenu9 && (
            <div className='section-body'>
                {data.ProductData10.map((item, index)=>{
                        return(
                            <MenusMap 
                            img={item.img} 
                            title={item.title}
                            price={item.price} 
                            contant={item.contant} 
                            item={item}
                            key={index}  
                            />
                        )
                    })}
            </div>
            )}
        </div>
    </div>
)
}

export default Sections
