import img1 from './images/ice cream (1).jpg'
import img2 from './images/ice cream (2).jpg'
import img3 from './images/ice cream (3).jpg'
import img4 from './images/ice cream (4).jpg'
import img5 from './images/ice cream (5).jpg'
import img6 from './images/ice cream (6).jpg'
import img7 from './images/ice cream (7).jpg'
import img8 from './images/ice cream (8).jpg'
import img9 from './images/ice cream (9).jpg'
import img10 from './images/ice cream (10).jpg'
import img11 from './images/ice cream (11).jpg'
import img12 from './images/ice cream (12).jpg'



const Data ={
    Products :[
        {
        id:1,
        img:img1,
        title:' mango',
        price:9,
        
        },
        {
        id:2,
        img:img2,
        title:'banana',
        price:10
        },
        {
        id:3,
        img:img3,
        title:'kiwi',
        price:13
        },
        {
        id:4,
        img:img4,
        title:'watermelon',
        price:5
        },
        {
        id:5,
        img:img5,
        title:'Fruit basket',
        price:8
        },
        {
        id:6,
        img:img6,
        title:'Fruits',
        price:8
        },
        {
        id:7,
        img:img7,
        title:'orange',
        price:8
        },
        {
        id:8,
        img:img8,
        title:'cherry',
        price:7
        },
        {
        id:9,
        img:img9,
        title:'fruits',
        price:8
        },
        {
        id:10,
        img:img10,
        title:'orange',
        price:9
        },
        {
        id:11,
        img:img11,
        title:'orange',
        price:6
        },
        {
        id:12,
        img:img12,
        title:'fruits',
        price:8
        }
    ]
}

export default Data;