import img1 from './images/dessert (1).jpg'
import img2 from './images/dessert (2).jpg'
import img3 from './images/dessert (3).jpg'
import img4 from './images/dessert (4).jpg'
import img5 from './images/dessert (5).jpg'
import img6 from './images/dessert (6).jpg'
import img7 from './images/dessert (7).jpg'
import img8 from './images/dessert (8).jpg'
import img9 from './images/dessert (9).jpg'
import img10 from './images/dessert (10).jpg'
import img11 from './images/dessert (11).jpg'
import img12 from './images/dessert (12).jpg'



const Data ={
    Products :[
        {
        id:1,
        img:img1,
        title:' Strawberry tart',
        price:9,
        
        },
        {
        id:2,
        img:img2,
        title:'pancake',
        price:10
        },
        {
        id:3,
        img:img3,
        title:'cream cake',
        price:13
        },
        {
        id:4,
        img:img4,
        title:'Package of cake & juice',
        price:5
        },
        {
        id:5,
        img:img5,
        title:'pinuts cake',
        price:8
        },
        {
        id:6,
        img:img6,
        title:'cup cake',
        price:8
        },
        {
        id:7,
        img:img7,
        title:'cream cake',
        price:8
        },
        {
        id:8,
        img:img8,
        title:'Chocolate cake',
        price:7
        },
        {
        id:9,
        img:img9,
        title:'pastry',
        price:8
        },
        {
        id:10,
        img:img10,
        title:'cake',
        price:9
        },
        {
        id:11,
        img:img11,
        title:'cream cake',
        price:6
        },
        {
        id:12,
        img:img12,
        title:'fruit cake',
        price:8
        }
    ]
}

export default Data;