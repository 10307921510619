import React, { useState } from 'react';
import './Navbar.css';
import image1 from './images/logo.png';
import { Fade } from 'react-reveal';
import { Link} from 'react-router-dom'
import {useCart} from 'react-use-cart'
import { BiCart } from 'react-icons/bi';
import { RiMenu3Line, RiCloseLine, RiArrowDownLine } from 'react-icons/ri';



function Navbar() {
    const [ toggleMenu, setToggleMenu] = useState(false);
    const [ open, setOpen] = useState(false);
    const [ open1, setOpen1] = useState(false);
    const [ open2, setOpen2] = useState(false);
    const [ open3, setOpen3] = useState(false);
    const [ header, setHeader] = useState(false);
    const { totalItems} = useCart();
    
    
    const change = ()=>{
        if(window.scrollY >= 100){
            setHeader(true);
        }else{
            setHeader(false);
        }
    };
    window.addEventListener('scroll', change);
    
    
return (
    
    <div className={header ?'header sticky':'header'}>
        
            <div className="logo">
                <img src={image1} alt=''/>
                <h1>Foodia</h1>
            </div>
            <div class="links1">
                <div  className="navbar">
                    <div className='links-main'>
                            <div className='active-link' >Home<i class="fa-solid fa-chevron-down"></i></div>
                            <div className= "mylink-menu-active">
                                <p><Link to="/HomeRestaurant">Restaurant Home </Link></p>
                                <p><Link to="/HomeFastFood"> Fast food home</Link></p>
                                <p><Link to="/HomeBigMeals"> big meals home</Link></p>
                                <p><Link to="/HomeGirlled"> Grilled home</Link></p>
                            </div>
                        </div>
                            
                        <div className='links-main'>
                            <div className='active-link' >shop<i class="fa-solid fa-chevron-down"></i></div>
                            <div className= "mylink-menu-active">
                                <p><Link to="/Desserts">Desserts</Link></p>
                                <p><Link to="/Fruits">Fruits and juices</Link></p>
                                <p><Link to="/IceCream">ice cream</Link></p>
                                <p><Link to="/Coffee">coffee</Link></p>
                            </div>
                        </div>
                        <div className='links-main'>
                            <div className='active-link' >pages<i class="fa-solid fa-chevron-down"></i></div>
                            <div className= "mylink-menu-active">
                                <p><Link to="/Blog">blog</Link></p>
                                <p><Link to="/Menus">menus</Link></p>
                                <p><Link to="/SignInPage">Sign In</Link></p>
                                <p><Link to="/SignUpPage">Sign Up</Link></p>
                                <p><Link to="/Gallary">Gallary</Link></p>
                                <p><Link to="/MyAccount">My Account</Link></p>
                                <p><Link to="/NonePage">404</Link></p>
                            </div>
                        </div>
                                <div className='links-main'>
                                <div className='active-link' >about us<i class="fa-solid fa-chevron-down"></i></div>
                                    <div className= "mylink-menu-active">
                                        <p><Link to="/About" >about us</Link></p>
                                        <p><Link to="/Chefs" >chefs</Link></p>
                                    </div>
                                </div>
                                <div className='links-main'>
                                        <div className='active-link'><Link to="/MyCart" className='cart-link' >my cart <BiCart size='2rem' /><span className="count">{totalItems}</span></Link></div>
                                </div>
                </div>
            </div>
            <div class="links2" >
                <div className='icons' >
                {toggleMenu
                ? <RiCloseLine className='icons-icon' size={27} onClick={() => setToggleMenu(false)} />
                : <RiMenu3Line className='icons-icon' size={27} onClick={() => setToggleMenu(true)} />
                }
                </div>
                
                <div  className={`navbar ${toggleMenu?'active':'inactive'}`}>
                    <div className='smale-menu'>
                        <div className='menu-link' >Home
                        {open
                        ? <RiCloseLine className='icons-icon' size={27} onClick={() => setOpen(false)} />
                        : <RiArrowDownLine className='icons-icon' size={27} onClick={() => setOpen(true)} />
                        }</div>
                        {open &&(
                            <Fade left duration={1000}>
                            <div className='menu-link-active-home' >
                            <p><Link to="/HomeRestaurant">Restaurant Home </Link></p>
                            <p><Link to="/HomeFastFood"> Fast food home</Link></p>
                            <p><Link to="/HomeBigMeals"> big meals home</Link></p>
                            <p><Link to="/HomeGirlled"> Grilled home</Link></p>    
                        </div>
                        </Fade>
                        )}
                        
                    </div>
                    <div className='smale-menu'>
                        <div className='menu-link' >shop{open1
                        ? <RiCloseLine className='icons-icon' size={27} onClick={() => setOpen1(false)} />
                        : <RiArrowDownLine className='icons-icon' size={27} onClick={() => setOpen1(true)} />
                        }</div>
                        {open1 &&(
                            <Fade right duration={750}>
                            <div className='menu-link-active-home'>
                                <p><Link to="/Desserts">Desserts</Link></p>
                                <p><Link to="/Fruits">Fruits and juices</Link></p>
                                <p><Link to="/IceCream">ice cream</Link></p>
                                <p><Link to="/Coffee">coffee</Link></p>  
                            </div>
                            </Fade>
                        )}
                        
                    </div>
                    <div className='smale-menu'>
                        <div className='menu-link' >pages{open2
                        ? <RiCloseLine className='icons-icon' size={27} onClick={() => setOpen2(false)} />
                        : <RiArrowDownLine className='icons-icon' size={27} onClick={() => setOpen2(true)} />
                        }</div>
                        {open2 &&(
                            <Fade left duration={750}>
                            <div className='menu-link-active-home'>
                                <p><Link to="/Blog">blog</Link></p>
                                <p><Link to="/Menus">menus</Link></p>
                                <p><Link to="/SignInPage">Sign In</Link></p>
                                <p><Link to="/SignUpPage">Sign Up</Link></p>
                                <p><Link to="/Gallary">Gallary</Link></p>
                                <p><Link to="/MyAccount">My Account</Link></p>
                                <p><Link to="/NonePage">404</Link></p>  
                            </div>
                            </Fade>
                        )}
                        
                    </div>
                    <div className='smale-menu'>
                        <div className='menu-link' >about us{open3
                        ? <RiCloseLine className='icons-icon' size={27} onClick={() => setOpen3(false)} />
                        : <RiArrowDownLine className='icons-icon' size={27} onClick={() => setOpen3(true)} />
                        }</div>
                        {open3 && (
                            <Fade right duration={750}>
                            <div className='menu-link-active-home'>
                                <p><Link to="/About" >about us</Link></p>
                                <p><Link to="/Chefs" >chefs</Link></p>
                            </div>
                            </Fade>
                            
                        )}
                        
                        
                    </div>
                    <div className='menu-link'>
                        <Link to="/MyCart" className='cart-link' >
                        my cart <BiCart size='2rem' /><span className="count">{totalItems}</span>
                        </Link>
                    </div>
                </div>
                
            </div>
        
        </div>
)
}

export default Navbar
