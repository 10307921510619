import React from 'react'
import MainHome from '../components/Master Home/MainHome'
function MHome() {
    return (
    <div>
        <MainHome/>
    </div>
    )
}

export default MHome
