import React from 'react'
import './Cart.css'
import { useCart } from 'react-use-cart'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';


function Cart() {
    const {
        isEmpty,
        totalUniqueItems,
        totalItems,
        cartTotal,
        updateItemQuantity,
        removeItem,
        emptyCart,
        items}=useCart();
        if(isEmpty)return<h1 className='headline'>your cart is empty</h1>
return (
    <section>
        <div className='mycart'>
        <div className='cart-header'>
        <h1>cart ({totalUniqueItems}) total Items:({totalItems})</h1>
        <div>
            <tbody>
            {items.map((item, index)=>{
                return(
                <tr className='cart-line' key={index}>
                    <td>
                        <img src={item.img} alt=''/>
                    </td>
                    <td className='line' style={{whiteSpace:'nowrap', width:"14rem",overflow:"hidden",textOverFlow:"ellipis"}}>{item.title}</td>
                    <br></br>
                    <td className='line' >{item.price}$</td>
                    <br></br>
                    <br></br>
                    <td className='line'>Quantity ({item.quantity})</td>
                    <td className='line'>
                        <Button variant="warning"  onClick={()=>updateItemQuantity(item.id, item.quantity -1)}>-</Button>
                        <Button variant="info" onClick={()=>updateItemQuantity(item.id, item.quantity +1)} >+</Button>
                        <Button variant="danger" onClick={()=>removeItem(item.id)}>Remove Item</Button>
                    </td>
                </tr>
                )
            })}
            </tbody>
            <div className='cart-line'>
            <h2>Total Price: {cartTotal} $</h2>
            <br></br>
            <br></br>
            <br></br>
            <Button variant="outline-danger" onClick={emptyCart}>Clear Cart</Button>
            <Button variant="outline-success" ><Link to='/Payment'>Buy now</Link></Button>
        </div>
        </div>
        </div>
        </div>
        </section>
)
}

export default Cart
