import React from 'react';
import data from './Data/Data'
import MenuMap from './MenuMap';
import './Menu.css'
import Fade from 'react-reveal';




function BigMealMenu(props) {
  return (
    <div className='menu-main'>
        <div class="title">
                <Fade top duration={2000} delay={2000}>
                <h1>BIG MEAL MENU</h1>
                </Fade>
        </div>
        <Fade up duration={2000} delay={1000}>
            <div className='menu-item'>
                {data.productData2.map((item, index)=>{
                            return(
                                <MenuMap 
                                img={item.img} 
                                title={item.title}
                                price={item.price} 
                                contant={item.contant} 
                                item={item}
                                key={index}  
                                />
                            )
                        })}
        </div>
        </Fade>
        
        </div>
  )
}

export default BigMealMenu
