import React, { useEffect, useState } from 'react'
import './GirlledLanding.css'
import sliderData from '../data'




function GirlledLanding() {
    const [showSlider, setShowSlider] = useState(0);
    const sliderLength = sliderData.length;
    const autoScroll = true;
    let slideInterval;
    let intervalTime =5000;
    function auto (){
        slideInterval = setInterval(nextSlide,intervalTime);
    }
    const nextSlide = () => {
        setShowSlider(showSlider === sliderLength - 1 ? 0 : showSlider + 1);
    };
    const prevSlide = () => {
        setShowSlider(showSlider === 0 ? sliderLength - 1 : showSlider - 1);
    };
    
    useEffect(()=>{
        setShowSlider(0);
    },[]);
    useEffect(()=>{
        if(autoScroll) {
            auto();
        }
        return () => clearInterval(slideInterval);
    } );
    return (
        <div className='main-girlled-landing'>
            <i class="fa-solid fa-arrow-left " onClick={prevSlide}></i>
            <i class="fa-solid fa-arrow-right" onClick={nextSlide}></i>
            {sliderData.map((slide, index)=>{
            return(
                            <div className={index === showSlider ?
                            "slide active" : "slide"} key={index}>
                                {index === showSlider && (
                                    <div>
                                        <img src={slide.img} alt=''/>
                                        <div className='content'>
                                            <h2>{slide.title}</h2>
                                            
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    })}
        </div>
    )
}

export default GirlledLanding
