import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Landing from '../components/Shop Pages/ice cream/Landing/Landing'
import Slider from '../components/Shop Pages/ice cream/Slider/FoodSlider'
import Menu from '../components/Shop Pages/ice cream/Menu/Menu'
import Gallary from '../components/Shop Pages/ice cream/Gallary/Gallary'
import Reviews from '../components/Shop Pages/ice cream/Reviews/Reviews'
import Description from '../components/Shop Pages/ice cream/Description/Description'
import Booking from '../components/Shop Pages/ice cream/Booking/Booking'
import Footer from '../components/Commen Components/Footer/Footer'
function IceCream() {
  return (
    <div>
      <Navbar/>
      <Landing/>
      <Slider/>
      <Menu/>
      <Gallary/>
      <Reviews/>
      <Description/>
      <Booking/>
      <Footer/>
    </div>
  )
}

export default IceCream
