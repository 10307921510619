import img1 from './images/coffee (1).jpg'
import img2 from './images/coffee (2).jpg'
import img3 from './images/coffee (3).jpg'
import img4 from './images/coffee (4).jpg'
import img5 from './images/coffee (5).jpg'
import img6 from './images/coffee (6).jpg'
import img7 from './images/coffee (7).jpg'
import img8 from './images/coffee (8).jpg'
import img9 from './images/coffee (9).jpg'
import img10 from './images/coffee (10).jpg'




const Data ={
    Products :[
        {
        id:1,
        img:img1,
        title:' coffee',
        price:5,
        
        },
        {
        id:2,
        img:img2,
        title:'coffee',
        price:5
        },
        {
        id:3,
        img:img3,
        title:'coffee',
        price:3
        },
        {
        id:4,
        img:img4,
        title:'coffee',
        price:5
        },
        {
        id:5,
        img:img5,
        title:'coffee',
        price:8
        },
        {
        id:6,
        img:img6,
        title:'coffee',
        price:8
        },
        {
        id:7,
        img:img7,
        title:'coffee',
        price:8
        },
        {
        id:8,
        img:img8,
        title:'coffee',
        price:8
        },
        {
        id:9,
        img:img9,
        title:'coffee',
        price:8
        },
        {
        id:10,
        img:img10,
        title:'coffee',
        price:8
        }
    ]
}

export default Data;