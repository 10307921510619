import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Footer from '../components/Commen Components/Footer/Footer'
import Chef from '../components/About Us Pages/chef/Chef'
import Landing from '../components/About Us Pages/chef/Landing/Landing'

function Chefs() {
    return (
    <div>
        <Navbar/>
        <Landing/>
        <Chef/>
        <Footer/>
    </div>
    )
}

export default Chefs
