import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import './Reviews.css'
import pizza1 from './images/pizza (1).jpg'
import burger1 from './images/burger (1).jpg'
import crispy1 from './images/crispy (1).jpg'
import shawerma1 from './images/shawerma (1).jpg'
import pizza2 from './images/pizza (2).jpg'
import burger2 from './images/burger (2).jpg'
import crispy2 from './images/crispy (2).jpg'
import shawerma2 from './images/shawerma (2).jpg'
import pizza3 from './images/pizza (3).jpg'
import burger3 from './images/burger (3).jpg'
import crispy3 from './images/crispy (3).jpg'
import shawerma3 from './images/shawerma (3).jpg'


function Reviews() {
    return (
    <div className='review-main'>
        <h1> Foodia FastFood images</h1>
            <Swiper
            spaceBetween={30}
            effect={'fade'}
            navigation={true}
            autoplay={{
            delay: 1500,
            disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            loop={true}
            modules={[EffectFade, Navigation, Pagination, Autoplay]}
            className="mySwiper"
            >
            <SwiperSlide className='swiper-slide'>
                <img src={pizza1} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={burger1} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={crispy1} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={shawerma1} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={pizza2} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={burger2} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={crispy2} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={shawerma2} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={pizza3} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={burger3} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={crispy3} alt=''/>
            </SwiperSlide>
            <SwiperSlide >
                <img src={shawerma3} alt=''/>
            </SwiperSlide>
            </Swiper>
    </div>
    )
}

export default Reviews
