import img1 from './images/pizza (1).jpg'
import img2 from './images/pizza (2).jpg'
import img3 from './images/pizza (3).jpg'
import img4 from './images/pizza (4).jpg'
import img5 from './images/big meal (1).jpg'
import img6 from './images/big meal (2).jpg'
import img7 from './images/big meal (3).jpg'
import img8 from './images/big meal (4).jpg'
import img9 from './images/burger (1).jpg'
import img10 from './images/burger (2).jpg'
import img11 from './images/burger (3).jpg'
import img12 from './images/burger (4).jpg'
import img13 from './images/crispy (6).jpg'
import img14 from './images/crispy (4).jpg'
import img15 from './images/crispy (3).jpg'
import img16 from './images/crispy (2).jpg'
import img17 from './images/grilled (1).jpg'
import img18 from './images/grilled (3).jpg'
import img19 from './images/grilled (5).jpg'
import img20 from './images/grilled (6).jpg'
import img21 from './images/shawerma (1).jpg'
import img22 from './images/shawerma (2).jpg'
import img23 from './images/shawerma (3).jpg'
import img24 from './images/shawerma (4).jpg'
import img25 from './images/coffee (1).jpg'
import img26 from './images/coffee (2).jpg'
import img27 from './images/coffee (3).jpg'
import img28 from './images/coffee (4).jpg'
import img29 from './images/coffee (5).jpg'
import img30 from './images/coffee (6).jpg'
import img31 from './images/coffee (7).jpg'
import img32 from './images/coffee (8).jpg'
import img33 from './images/coffee (9).jpg'
import img34 from './images/coffee (10).jpg'
import img35 from './images/dessert (1).jpg'
import img36 from './images/dessert (2).jpg'
import img37 from './images/dessert (3).jpg'
import img38 from './images/dessert (4).jpg'
import img39 from './images/dessert (5).jpg'
import img40 from './images/dessert (6).jpg'
import img41 from './images/dessert (7).jpg'
import img42 from './images/dessert (8).jpg'
import img43 from './images/dessert (9).jpg'
import img44 from './images/dessert (10).jpg'
import img45 from './images/dessert (11).jpg'
import img46 from './images/dessert (12).jpg'
import img47 from './images/fruit (1).jpg'
import img48 from './images/fruit (2).jpg'
import img49 from './images/fruit (3).jpg'
import img50 from './images/fruit (4).jpg'
import img51 from './images/fruit (5).jpg'
import img52 from './images/fruit (6).jpg'
import img53 from './images/fruit (7).jpg'
import img54 from './images/fruit (8).jpg'
import img55 from './images/fruit (9).jpg'
import img56 from './images/fruit (10).jpg'
import img57 from './images/fruit (11).jpg'
import img58 from './images/fruit (12).jpg'
import img59 from './images/ice cream (1).jpg'
import img60 from './images/ice cream (2).jpg'
import img61 from './images/ice cream (3).jpg'
import img62 from './images/ice cream (4).jpg'
import img63 from './images/ice cream (5).jpg'
import img64 from './images/ice cream (6).jpg'
import img65 from './images/ice cream (7).jpg'
import img66 from './images/ice cream (8).jpg'
import img67 from './images/ice cream (9).jpg'
import img68 from './images/ice cream (10).jpg'
import img69 from './images/ice cream (11).jpg'
import img70 from './images/ice cream (12).jpg'


const Items ={
    productData:[
        {
        id:1,
        img:img1,
        title:'margrita',
        price:25,
        contant:'1 margrita pizza + French fries + pepsi'
        },
        {
        id:2,
        img:img2,
        title:'chicken pizza',
        price:25,
        contant:'1 chicken pizza + French fries + pepsi '
        },
        {
        id:3,
        img:img3,
        title:'chrimp pizza',
        price:3,
        contant:'1 chrimp pizza + French fries + pepsi'
        },
        {
        id:4,
        img:img4,
        title:'mix pizza',
        price:8,
        contant:'1 mix pizza + French fries + pepsi'
        }
    ],
    productData2:[
        {
        id:5,
        img:img5,
        title:'big sandwich meal',
        price:15,
        contant:'1 big sandwich meal + salad '
        },
        {
        id:6,
        img:img6,
        title:' big chicken sandwich',
        price:7,
        contant:'1 big chicken sandwich + salad '
        },
        {
        id:7,
        img:img7,
        title:' big burger meal',
        price:5,
        contant:'3 big burger + French fries + pepsi'
        },
        {
        id:8,
        img:img8,
        title:'big chicken meal',
        price:8,
        contant:'1 chicken + salad '
        }
    ],
    productData3:[
        {
        id:9,
        img:img9,
        title:'burger',
        price:7,
        contant:'1 burger + cocacola'
        },
        {
        id:10,
        img:img10,
        title:'trible burger',
        price:13,
        contant:'1 trible burger + cocacola'
        },
        {
        id:11,
        img:img11,
        title:' chicken burger',
        price:15,
        contant:'chicken burger + French fries'
        },
        {
        id:12,
        img:img12,
        title:'mix burger',
        price:8,
        contant:'1 burger + French fries + cocacola'
        }
    ],
    productData4:[
        {
        id:13,
        img:img13,
        title:'crispy meal',
        price:15,
        contant:'4 crispy chicken peice + French fries '
        },
        {
        id:14,
        img:img14,
        title:'crispy meal',
        price:15,
        contant:'2 crispy chicken peice + French fries + Tomia'
        },
        {
        id:15,
        img:img15,
        title:' crispy salad',
        price:12,
        contant:'1 crispy salad + French fries '
        },
        {
        id:16,
        img:img16,
        title:'crispy chicken dish',
        price:18,
        contant:'2 crispy chicken dish + French fries + salad'
        }
    ],
    productData5:[
        {
        id:17,
        img:img17,
        title:'grilled chicken',
        price:35,
        contant:'2 grilled chicken  +  French fries + pepsi'
        },
        {
        id:18,
        img:img18,
        title:'Grilled meat',
        price:35,
        contant:' Grilled meat +  French fries +  pepsi'
        },
        {
        id:19,
        img:img19,
        title:'  mix grilled',
        price:20,
        contant:'mix grilled +  French fries +  pepsi'
        },
        {
        id:20,
        img:img20,
        title:'chicken Grill',
        price:28,
        contant:'4 chicken Grill + French fries + 2 pepsi'
        }
    ],
    productData6:[
        {
        id:21,
        img:img21,
        title:'shawerma araby',
        price:15,
        contant:'1 shawerma araby + 1 French fries + 1 pepsi + tomia'
        },
        {
        id:22,
        img:img22,
        title:'chicken shawerma',
        price:17,
        contant:'1 chicken shawerma + 1 French fries + 1 pepsi + tomia'
        },
        {
        id:23,
        img:img23,
        title:'  mix shawerma',
        price:21,
        contant:' mix shawerma + 1 French fries + 1 pepsi + tomia'
        },
        {
        id:24,
        img:img24,
        title:'beaf shawerma',
        price:18,
        contant:'beaf shawerma + 1 French fries + 1 pepsi + tomia'
        }
    ],
    productData7 :[
        {
        id:25,
        img:img25,
        title:' coffee',
        price:5,
        
        },
        {
        id:26,
        img:img26,
        title:'coffee',
        price:5
        },
        {
        id:27,
        img:img27,
        title:'coffee',
        price:3
        },
        {
        id:28,
        img:img28,
        title:'coffee',
        price:5
        },
        {
        id:29,
        img:img29,
        title:'coffee',
        price:8
        },
        {
        id:30,
        img:img30,
        title:'coffee',
        price:8
        },
        {
        id:31,
        img:img31,
        title:'coffee',
        price:8
        },
        {
        id:32,
        img:img32,
        title:'coffee',
        price:8
        },
        {
        id:33,
        img:img33,
        title:'coffee',
        price:8
        },
        {
        id:34,
        img:img34,
        title:'coffee',
        price:8
        }
    ],
    ProductData8 :[
        {
        id:35,
        img:img35,
        title:' Strawberry tart',
        price:9,
        
        },
        {
        id:36,
        img:img36,
        title:'pancake',
        price:10
        },
        {
        id:37,
        img:img37,
        title:'cream cake',
        price:13
        },
        {
        id:38,
        img:img38,
        title:'Package of cake & juice',
        price:5
        },
        {
        id:39,
        img:img39,
        title:'pinuts cake',
        price:8
        },
        {
        id:40,
        img:img40,
        title:'cup cake',
        price:8
        },
        {
        id:41,
        img:img41,
        title:'cream cake',
        price:8
        },
        {
        id:42,
        img:img42,
        title:'Chocolate cake',
        price:7
        },
        {
        id:43,
        img:img43,
        title:'pastry',
        price:8
        },
        {
        id:44,
        img:img44,
        title:'cake',
        price:9
        },
        {
        id:45,
        img:img45,
        title:'cream cake',
        price:6
        },
        {
        id:46,
        img:img46,
        title:'fruit cake',
        price:8
        }
    ],
    ProductData9 :[
        {
        id:47,
        img:img47,
        title:' mango',
        price:9,
        
        },
        {
        id:48,
        img:img48,
        title:'banana',
        price:10
        },
        {
        id:49,
        img:img49,
        title:'kiwi',
        price:13
        },
        {
        id:50,
        img:img50,
        title:'watermelon',
        price:5
        },
        {
        id:51,
        img:img51,
        title:'Fruit basket',
        price:8
        },
        {
        id:52,
        img:img52,
        title:'Fruits',
        price:8
        },
        {
        id:53,
        img:img53,
        title:'orange',
        price:8
        },
        {
        id:54,
        img:img54,
        title:'cherry',
        price:7
        },
        {
        id:55,
        img:img55,
        title:'fruits',
        price:8
        },
        {
        id:56,
        img:img56,
        title:'orange',
        price:9
        },
        {
        id:57,
        img:img57,
        title:'orange',
        price:6
        },
        {
        id:58,
        img:img58,
        title:'fruits',
        price:8
        }
    ],
    ProductData10 :[
        {
        id:59,
        img:img59,
        title:' mango',
        price:9,
        
        },
        {
        id:60,
        img:img60,
        title:'banana',
        price:10
        },
        {
        id:61,
        img:img61,
        title:'kiwi',
        price:13
        },
        {
        id:62,
        img:img62,
        title:'watermelon',
        price:5
        },
        {
        id:63,
        img:img63,
        title:'Fruit ',
        price:8
        },
        {
        id:64,
        img:img64,
        title:'Fruits',
        price:8
        },
        {
        id:65,
        img:img65,
        title:'orange',
        price:8
        },
        {
        id:66,
        img:img66,
        title:'cherry',
        price:7
        },
        {
        id:67,
        img:img67,
        title:'fruits',
        price:8
        },
        {
        id:68,
        img:img68,
        title:'orange',
        price:9
        },
        {
        id:69,
        img:img69,
        title:'orange',
        price:6
        },
        {
        id:70,
        img:img70,
        title:'fruits',
        price:8
        }
    ]
};

export default Items;