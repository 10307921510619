import React from 'react'
import './MLanding.css'
import { Fade } from 'react-reveal'

function MLanding() {
    return (
    <div className='mlanding-main'>
        <div className='m-intro'>
            <h1>Big Meal in Foodia</h1>
            <Fade up duration={2000} delay={1000}>
            <div className='m-intro-body'>
                <h1>Enjoy</h1>
                <h2> with your family</h2>
                <h2>with your frinds</h2>
            </div>
            </Fade>
        </div>
    </div>
    )
}

export default MLanding
