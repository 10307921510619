import React from 'react'
import './Services.css'
import { Button } from 'react-bootstrap'
import { Fade } from 'react-reveal'
function Services() {
  return (
    <div className='services-main'>
      <Fade left duration={1500} delay={1000}>
        <div className="services-item">
          <div className='icons'>
            <i class="fa-solid fa-burger"></i>
          </div>
            <h1> big burger</h1>
            <p>Sit amet consectetur adipiscing elitcs Auctor venenatis tincidunt</p>
            <Button variant="outline-success">Read More
              <i class="fa-solid fa-arrow-right"></i>
            </Button>
        </div>
      </Fade>
      <Fade up duration={1500} delay={1000}>
        <div className="services-item">
          <div className='icons'>
            <i class="fa-solid fa-mug-hot"></i>
          </div>
            <h1> coffee</h1>
            <p>Sit amet consectetur adipiscing elitcs Auctor venenatis tincidunt</p>
            <Button variant="outline-success">Read More
              <i class="fa-solid fa-arrow-right"></i>
            </Button>
        </div>
      </Fade>
      <Fade right duration={1500} delay={1000}>
        <div className="services-item">
          <div className='icons'>
            <i class="fa-solid fa-drumstick-bite"></i>
          </div>
            <h1> big chicken</h1>
            <p>Sit amet consectetur adipiscing elitcs Auctor venenatis tincidunt</p>
            <Button variant="outline-success">Read More
              <i class="fa-solid fa-arrow-right"></i>
            </Button>
        </div>
      </Fade>
    </div>
  )
}

export default Services