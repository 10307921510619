import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Footer from '../components/Commen Components/Footer/Footer'
import PayPage from '../components/Commen Components/Payment Page/PayPage'

function Payment() {
    return (
    <div>
        <Navbar/>
        <PayPage/>
        <Footer/>
    </div>
    )
}

export default Payment
