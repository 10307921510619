import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Footer from '../components/Commen Components/Footer/Footer'
import BlogPage from '../components/Blog Pages/Blog/BlogPage'
import Landing from "../components/Blog Pages/Blog/Landing/Landing";
import Offers from '../components/Blog Pages/Blog/Offers/Offers';


function Blog() {
    return (
    <div>
        <Navbar/>
        <Landing/>
        <BlogPage/>
        <Offers/>
        <Footer/>
    </div>
    )
}

export default Blog
