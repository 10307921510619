import React from "react";
import Navbar from "../components/Commen Components/Navbar/Navbar";
import Landing from "../components/Home Pages/RestaurantHome/Landing/Landing";
import Offers from "../components/Home Pages/RestaurantHome/Offers/Offers";
import OurServices from "../components/Home Pages/RestaurantHome/OurServices/OurServices";
import Meals from "../components/Home Pages/RestaurantHome/meals/Meals";
import Team from "../components/Home Pages/RestaurantHome/Teem/Team";
import Contact from '../components/Home Pages/RestaurantHome/Contact/Contact' 
import Footer from "../components/Commen Components/Footer/Footer";
import Booking from "../components/Home Pages/RestaurantHome/Booking/Booking";


const Home = () => {
    
    return(
        <div className="homediv">
            <Navbar/>
            <Landing />
            <OurServices/>
            <Offers />
            <Meals />
            <Booking/>
            <Team/>
            <Contact/>
            <Footer/>
        </div>
    )
}
export default Home;