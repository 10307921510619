import Girlled1 from './assets/girlled (1).jpg'
import Girlled2 from './assets/girlled (2).jpg'
import Girlled3 from './assets/girlled (3).jpg'
import Girlled4 from './assets/girlled (4).jpg'
import Girlled5 from './assets/girlled (5).jpg'
import Girlled6 from './assets/girlled (6).jpg'
import Girlled7 from './assets/girlled (7).jpg'

  const sliderData = [
{
  img:Girlled1,
  title:' girlled Family meal',
  content:'girlled meet and chicken ',
  price: 75
},
{
  img:Girlled2,
  title:' grilled chicken meal',
  content:'grilled chicken with Boiled vegetables ',
  price:50
},
{
  img:Girlled3,
  title:' grilled sandwich',
  content:'grilled sandwich with french fries ',
  price:19
},
{
  img:Girlled4,
  title:' grilled burger sandwich',
  content:'grilled burger sandwich with french fries ',
  price:21
},
{
  img:Girlled5,
  title:' grilled chicken ',
  content:'grilled chicken with Boiled vegetables ',
  price:22
},
{
  img:Girlled6,
  title:' Grilled meat ',
  content:'Grilled meat pieces with Boiled vegetables ',
  price:105
},
{
  img:Girlled7,
  title:' grilled master ',
  content:'make your a delicious grilled dish ',
  price:121
}
]
export default sliderData;