import React from 'react';
import data from './Data/Data'
import MenuMap from './MenuMap';
import './Menu.css'
import { Zoom } from 'react-reveal';
import Fade from 'react-reveal';




function FastFoodMenu(props) {
  return (
    <div className='menu-main'>
        <div class="title">
                <Fade top duration={2000} delay={2000}>
                <h1>FAST FOOD MENU</h1>
                </Fade>
        </div>
        <Fade up duration={2000} delay={1000}>
            <div className='menu-item'>
                {data.productData.map((item, index)=>{
                            return(
                                <MenuMap 
                                img={item.img} 
                                title={item.title}
                                price={item.price} 
                                contant={item.contant} 
                                item={item}
                                key={index}  
                                />
                            )
                        })}
        </div>
        </Fade>
        <Zoom duration={2000} delay={1000}>
        <div className='menu-item'>
                {data.productData4.map((item, index)=>{
                            return(
                                <MenuMap 
                                img={item.img} 
                                title={item.title}
                                price={item.price} 
                                contant={item.contant} 
                                item={item}
                                key={index}  
                                />
                            )
                        })}
        </div>
        </Zoom>
        <Fade up duration={2000} delay={1000}>
        <div className='menu-item'>
                {data.productData3.map((item, index)=>{
                            return(
                                <MenuMap 
                                img={item.img} 
                                title={item.title}
                                price={item.price} 
                                contant={item.contant} 
                                item={item}
                                key={index}  
                                />
                            )
                        })}
        </div>
        </Fade>
        <Zoom duration={2000} delay={1000}>
        <div className='menu-item'>
                {data.productData6.map((item, index)=>{
                            return(
                                <MenuMap 
                                img={item.img} 
                                title={item.title}
                                price={item.price} 
                                contant={item.contant} 
                                item={item}
                                key={index}  
                                />
                            )
                        })}
        </div>
        </Zoom>
        </div>
  )
}

export default FastFoodMenu
