import React from 'react';
import './App.css';
import { CartProvider } from 'react-use-cart';
import {BrowserRouter as Router,  Routes as Switch, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Menus from './pages/Menus';
import MyCart from './pages/MyCart.jsx';
import About from './pages/About';
import HomeBigMeals from './pages/HomeBigMeals';
import HomeFastFood from './pages/HomeFastFood';
import HomeGirlled from './pages/HomeGirlled';
import HomeRestaurant  from './pages/HomeRestaurant';
import MasterHome  from './pages/MasterHome';
import Gallary  from './pages/Gallary';
import NonePage  from './pages/NonePage';
import Desserts from './pages/Desserts';
import Fruits from './pages/Fruits';
import IceCream from './pages/IceCream';
import Coffee from './pages/Coffee';
import Blog from './pages/Blog';
import Chefs from './pages/Chefs';
import Payment from './pages/Payment';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import MyAccount from './pages/MyAccount';

function App () {
  return (
    <CartProvider>
    <Router>
      <div className="App">
        <Switch>
          <Route exact path='/' element={<MasterHome />}></Route>
          <Route path='/HomeRestaurant' element={< HomeRestaurant/>}></Route>
          <Route path='/HomeFastFood' element={< HomeFastFood/>}></Route>
          <Route path='/HomeBigMeals' element={< HomeBigMeals/>}></Route>
          <Route path='/HomeGirlled' element={< HomeGirlled/>}></Route>
          <Route path='/Menus' element={<Menus />}></Route>
          <Route path='/Gallary' element={<Gallary />}></Route>
          <Route path='/NonePage' element={<NonePage />}></Route>
          <Route path='/MyCart' element={<MyCart />}></Route>
          <Route path='/About' element={<About />}></Route>
          <Route path='/Desserts' element={<Desserts />}></Route>
          <Route path='/Fruits' element={<Fruits />}></Route>
          <Route path='/IceCream' element={<IceCream />}></Route>
          <Route path='/Coffee' element={<Coffee />}></Route>
          <Route path='/Blog' element={<Blog />}></Route>
          <Route path='/Chefs' element={<Chefs />}></Route>
          <Route path='/Payment' element={<Payment />}></Route>
          <Route path='/SignInPage' element={<SignInPage />}></Route>
          <Route path='/SignUpPage' element={<SignUpPage />}></Route>
          <Route path='/MyAccount' element={<MyAccount />}></Route>
        </Switch>
    </div>
    </Router>
    </CartProvider>
  );
}

export default App;
