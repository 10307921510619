import React from 'react';
import './OurServices.css';
import { Fade } from 'react-reveal'



function OurServices() {
    
    return (
    <div className='sections'>
            <div class="title">
                <Fade down duration={2000} delay={1000}>
                <h1>our Services</h1>
                </Fade>
            </div>
            <Fade up duration={500} delay={500}>
                <div className='section'>
                    <div class="icons">
                        <i class="fa-solid fa-house"></i>
                    </div>
                    <h1>restaurant meals</h1>
                    <p>You can eat your favorite meal inside the restaurant, enjoy the delicious fresh taste, and listen to some music</p>
                </div>
            </Fade>
            <Fade up duration={500} delay={1000}>
                <div className='section'>
                    <div class="icons">
                        <i class="fa-solid fa-truck"></i>
                    </div>
                    <h1>Delivery Services</h1>
                    <p>If you do not intend to leave the house, we offer an express delivery service that will make you enjoy the delicious
                    fresh taste and save your precious time</p>
                </div>
            </Fade>
            <Fade up duration={500} delay={1500}>
                <div className='section'>
                <div class="icons">
                    <i class="fa-solid fa-cake-candles"></i>
                </div>
                <h1>Events and parties</h1>
                <p>If you have an occasion or a party, do not worry, dear, as we are able to offer the best deals on meals and desserts
                that you will like.</p>
            </div>
            </Fade>
        </div>
    )
}

export default OurServices
