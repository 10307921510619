import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar';
import Landing from '../components/Home Pages/RestaurantHome/Landing/Landing';
import Cart from '../components/Commen Components/MyCart/Cart';
import Footer from '../components/Commen Components/Footer/Footer'; 


const MyCart = () => {
    
    return (
        <div>
            <Navbar/>
            <Landing/>
            <Cart/>
            <Footer/>
        </div>
    )
}

export default MyCart;
