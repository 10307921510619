import React from 'react'
import './None.css'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
function nonePage() {
  return (
    <div className='main-nonepage'>
    <h1>OPPS! This Page are Can’t Be Found</h1>
    <p>The page you are looking for was moved, removed, renamed or never existed.</p>
    <Button variant="outline-warning"><p><Link to="/HomeRestaurant">back to Home Restaurant</Link></p></Button>
    </div>
  )
}

export default nonePage;
