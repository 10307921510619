import React from 'react'
import './Chef.css'
import { Fade } from 'react-reveal'
import img1 from './images/pizza chef.jpg'
import img2 from './images/team (1).jpg'
import img3 from './images/team (3).jpg'
import img4 from './images/team (4).jpg'
import img5 from './images/team (5).jpg'
import img6 from './images/team (6).jpg'

function Chef() {
    return (
    <div className='main-teem'>
        <div class="team-title">
            <h1>our amazing team</h1>
        </div>
        <Fade right duration={500} delay={500}>
        <div className='teem-white'>
            <img src={img1} alt=''/>
            <div className='teem-let'>
                <h1>james a barkar</h1>
                <p>Pizza manager</p>
                <p>a chef is a skilled professional who combines culinary expertise, creativity, and leadership to
                    create extraordinary dining experiences. They are responsible for menu planning, recipe development,
                    ingredient sourcing, food preparation, kitchen management, and team leadership.</p>
                    <div className='reviews'>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='far fa-star'></i>
                    </div>
            </div>
        </div>
        </Fade>
        <Fade left duration={500} delay={500}>
        <div className='teem-offwhite'>
            <img src={img2} alt=''/>
            <div className='teem-let'>
                <h1>james a barkar</h1>
                <p>Pizza manager</p>
                <p>a chef is a skilled professional who combines culinary expertise, creativity, and leadership to
                    create extraordinary dining experiences. They are responsible for menu planning, recipe development,
                    ingredient sourcing, food preparation, kitchen management, and team leadership.</p>
                    <div className='reviews'>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='far fa-star'></i>
                    </div>
            </div>
        </div>
        </Fade>
        <Fade right duration={500} delay={500}>
        <div className='teem-white'>
            <img src={img3} alt=''/>
            <div className='teem-let'>
                <h1>james a barkar</h1>
                <p>Pizza manager</p>
                <p>a chef is a skilled professional who combines culinary expertise, creativity, and leadership to
                    create extraordinary dining experiences. They are responsible for menu planning, recipe development,
                    ingredient sourcing, food preparation, kitchen management, and team leadership.</p>
                    <div className='reviews'>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='far fa-star'></i>
                    </div>
            </div>
        </div>
        </Fade>
        <Fade left duration={500} delay={500}>
        <div className='teem-offwhite'>
            <img src={img4} alt=''/>
            <div className='teem-let'>
                <h1>james a barkar</h1>
                <p>Pizza manager</p>
                <p>a chef is a skilled professional who combines culinary expertise, creativity, and leadership to
                    create extraordinary dining experiences. They are responsible for menu planning, recipe development,
                    ingredient sourcing, food preparation, kitchen management, and team leadership.</p>
                    <div className='reviews'>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='far fa-star'></i>
                    </div> 
            </div>
        </div>
        </Fade>
        <Fade right duration={500} delay={500}>
        <div className='teem-white'>
            <img src={img5} alt=''/>
            <div className='teem-let'>
                <h1>james a barkar</h1>
                <p>Pizza manager</p>
                <p>a chef is a skilled professional who combines culinary expertise, creativity, and leadership to
                    create extraordinary dining experiences. They are responsible for menu planning, recipe development,
                    ingredient sourcing, food preparation, kitchen management, and team leadership.</p>
                    <div className='reviews'>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='far fa-star'></i>
                    </div>
            </div>
        </div>
        </Fade>
        <Fade left duration={500} delay={500}>
        <div className='teem-offwhite'>
            <img src={img6} alt=''/>
            <div className='teem-let'>
                <h1>james a barkar</h1>
                <p>Pizza manager</p>
                <p>a chef is a skilled professional who combines culinary expertise, creativity, and leadership to
                    create extraordinary dining experiences. They are responsible for menu planning, recipe development,
                    ingredient sourcing, food preparation, kitchen management, and team leadership.</p>
                    <div className='reviews'>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='far fa-star'></i>
                    </div>
            </div>
        </div>
        </Fade>
    </div>
    )
}

export default Chef
