import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Landing from '../components/Home Pages/RestaurantHome/Landing/Landing'
import None from '../components/Blog Pages/404/None'
import Footer from '../components/Commen Components/Footer/Footer'

function NonePage() {
  return (
    <div>
        <Navbar/>
        <Landing/>
        <None/>
        <Footer/>
    </div>
  )
}

export default NonePage
