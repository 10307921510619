import img1 from './images/pizza (1).jpg'
import img2 from './images/pizza (2).jpg'
import img3 from './images/pizza (3).jpg'
import img4 from './images/pizza (4).jpg'
import img5 from './images/big meal (1).jpg'
import img6 from './images/big meal (2).jpg'
import img7 from './images/big meal (3).jpg'
import img8 from './images/big meal (4).jpg'
import img9 from './images/burger (1).jpg'
import img10 from './images/burger (2).jpg'
import img11 from './images/burger (3).jpg'
import img12 from './images/burger (4).jpg'
import img13 from './images/crispy (6).jpg'
import img14 from './images/crispy (4).jpg'
import img15 from './images/crispy (3).jpg'
import img16 from './images/crispy (2).jpg'
import img17 from './images/grilled (1).jpg'
import img18 from './images/grilled (3).jpg'
import img19 from './images/grilled (5).jpg'
import img20 from './images/grilled (6).jpg'
import img21 from './images/shawerma (1).jpg'
import img22 from './images/shawerma (2).jpg'
import img23 from './images/shawerma (3).jpg'
import img24 from './images/shawerma (4).jpg'


const Items ={
    productData:[
        {
        id:1,
        img:img1,
        title:'margrita',
        price:25,
        contant:'1 margrita pizza + French fries + pepsi'
        },
        {
        id:2,
        img:img2,
        title:'chicken pizza',
        price:25,
        contant:'1 chicken pizza + French fries + pepsi '
        },
        {
        id:3,
        img:img3,
        title:'chrimp pizza',
        price:3,
        contant:'1 chrimp pizza + French fries + pepsi'
        },
        {
        id:4,
        img:img4,
        title:'mix pizza',
        price:8,
        contant:'1 mix pizza + French fries + pepsi'
        }
    ],
    productData2:[
        {
        id:5,
        img:img5,
        title:'big sandwich meal',
        price:15,
        contant:'1 big sandwich meal + salad '
        },
        {
        id:6,
        img:img6,
        title:' big chicken sandwich',
        price:7,
        contant:'1 big chicken sandwich + salad '
        },
        {
        id:7,
        img:img7,
        title:' big burger meal',
        price:5,
        contant:'3 big burger + French fries + pepsi'
        },
        {
        id:8,
        img:img8,
        title:'big chicken meal',
        price:8,
        contant:'1 chicken + salad '
        }
    ],
    productData3:[
        {
        id:9,
        img:img9,
        title:'burger',
        price:7,
        contant:'1 burger + cocacola'
        },
        {
        id:10,
        img:img10,
        title:'trible burger',
        price:13,
        contant:'1 trible burger + cocacola'
        },
        {
        id:11,
        img:img11,
        title:' chicken burger',
        price:15,
        contant:'chicken burger + French fries'
        },
        {
        id:12,
        img:img12,
        title:'mix burger',
        price:8,
        contant:'1 burger + French fries + cocacola'
        }
    ],
    productData4:[
        {
        id:13,
        img:img13,
        title:'crispy meal',
        price:15,
        contant:'4 crispy chicken peice + French fries '
        },
        {
        id:14,
        img:img14,
        title:'crispy meal',
        price:15,
        contant:'2 crispy chicken peice + French fries + Tomia'
        },
        {
        id:15,
        img:img15,
        title:' crispy salad',
        price:12,
        contant:'1 crispy salad + French fries '
        },
        {
        id:16,
        img:img16,
        title:'crispy chicken dish',
        price:18,
        contant:'2 crispy chicken dish + French fries + salad'
        }
    ],
    productData5:[
        {
        id:17,
        img:img17,
        title:'grilled chicken',
        price:35,
        contant:'2 grilled chicken  +  French fries + pepsi'
        },
        {
        id:18,
        img:img18,
        title:'Grilled meat',
        price:35,
        contant:' Grilled meat +  French fries +  pepsi'
        },
        {
        id:19,
        img:img19,
        title:'  mix grilled',
        price:20,
        contant:'mix grilled +  French fries +  pepsi'
        },
        {
        id:20,
        img:img20,
        title:'chicken Grill',
        price:28,
        contant:'4 chicken Grill + French fries + 2 pepsi'
        }
    ],
    productData6:[
        {
        id:21,
        img:img21,
        title:'shawerma araby',
        price:15,
        contant:'1 shawerma araby + 1 French fries + 1 pepsi + tomia'
        },
        {
        id:22,
        img:img22,
        title:'chicken shawerma',
        price:17,
        contant:'1 chicken shawerma + 1 French fries + 1 pepsi + tomia'
        },
        {
        id:23,
        img:img23,
        title:'  mix shawerma',
        price:21,
        contant:' mix shawerma + 1 French fries + 1 pepsi + tomia'
        },
        {
        id:24,
        img:img24,
        title:'beaf shawerma',
        price:18,
        contant:'beaf shawerma + 1 French fries + 1 pepsi + tomia'
        }
    ]
};

export default Items;