import React from 'react'
import './Menu.css'
import { Button } from 'react-bootstrap';
import { useCart } from 'react-use-cart';
import {Fade} from 'react-reveal';

const MenuMap = (props) => {
    const {addItem} = useCart();
    return (
        <div className='menu-item-body'>
            <div className='item-image'>
                <img src={props.img } alt=''/>
            </div>
            <div className='item-content'>
                <Fade left duration={2000} delay={2000}>
                <h2>{props.title}</h2>
                <p>{props.price}$</p>
                </Fade>
                <Fade up duration={2000} delay={1000}>
                <Button variant="outline-success" onClick={()=>addItem(props.item)}>
                Add to Cart</Button>
                </Fade>
                
            </div>
        </div>
    )
}

export default MenuMap;