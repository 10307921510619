import React from 'react'
import './Menu.css'
import MenuMap from './MenuMap'
import Data from '../Data'

function Menu() {
  return (
    <div className='desserts-main'>
      <h1>desserts Menu</h1>
      <div className='desserts-body'>
                  {Data.Products.map((item, index)=>{
                          return(
                              <MenuMap 
                              img={item.img} 
                              title={item.title}
                              price={item.price} 
                              item={item}
                              key={index}  
                              />
                          )
                      })}
              </div>
          </div>
  )
}

export default Menu
