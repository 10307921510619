import React from 'react'
import './Description.css'
import cream1 from '../images/ice cream (1).jpg'
import cream8 from '../images/ice cream (6).jpg'

function Description() {
  return (
    <div className='description-main'>
        <div className='description-body'>
              <div className='description-letter'>
                <h1>why ice cream ?</h1>
                <p>Coffee, beverage brewed from the roasted and ground seeds of the tropical evergreen coffee
                  plants of African origin. Coffee is one of the three most popular beverages in the world (alongside water and tea) 
                  and one of the most profitable international commodities. Though coffee is the basis for an endless array of beverages, 
                  its popularity is mainly attributed to its invigorating effect, which is produced by caffeine, an alkaloid present in coffee.</p>
            </div>
            <div className='description-image'>
              <img src={cream1} alt=''/>
            </div>
      </div>
        <div className='description-body'>
              <div className='description-image'>
              <img src={cream8} alt=''/>
            </div>
              <div className='description-letter'>
                <h1>why ice cream from foodia ?</h1>
                <p>Coffee, beverage brewed from the roasted and ground seeds of the tropical evergreen coffee
                  plants of African origin. Coffee is one of the three most popular beverages in the world (alongside water and tea) 
                  and one of the most profitable international commodities. Though coffee is the basis for an endless array of beverages, 
                  its popularity is mainly attributed to its invigorating effect, which is produced by caffeine, an alkaloid present in coffee.</p>
            </div>
            
      </div>
    </div>
  )
}

export default Description
