import React from 'react'
import Navbar from '../components/Commen Components/Navbar/Navbar'
import Landing from '../components/Home Pages/FastFoodHome/Landing/Landing'
import FastFoodMenu from '../components/Home Pages/FastFoodHome/Menu/Menu'
import Booking from '../components/Home Pages/FastFoodHome/Booking/Booking'
import Footer from '../components/Commen Components/Footer/Footer'
import Reviews from '../components/Home Pages/FastFoodHome/Reviews/Reviews'
import Paragraph from '../components/Home Pages/FastFoodHome/paragraph/Paragraph'


function HomeFastFood() {
  return (
    <div>
      <Navbar/>
      <Landing/>
      <FastFoodMenu/>
      <Booking/>
      <Reviews/>
      <Paragraph/>
      <Footer/>
    </div>
  )
}

export default HomeFastFood;
